import PaymentLink, {
    clearCurrencyValue,
    formatCurrencyCodeSign, isLongCurrency,
} from "../../model/PaymentLink";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import UserAvatar from "./UserAvatar";
import EstablishmentLogo from "./EstablishmentLogo";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Alert, Card, Switch, Checkbox, FormControlLabel, TextField, useMediaQuery, useTheme} from "@mui/material";
import AppLogo from "./AppLogo";
import Establishment from "../../model/Establishment";
import User, {formatUserName, formatUserNameWithDefault} from "../../model/User";
import InitialLoading from "./InitialLoading";
import styled from "@emotion/styled";
import Impression1ImagePath from "../../images/impression1.png";
import Impression1ImagePath2x from "../../images/impression1@2x.png";
import Impression2ImagePath from "../../images/impression2.png";
import Impression2ImagePath2x from "../../images/impression2@2x.png";
import Impression3ImagePath from "../../images/impression3.png";
import Impression3ImagePath2x from "../../images/impression3@2x.png";
import Impression4ImagePath from "../../images/impression4.png";
import Impression4ImagePath2x from "../../images/impression4@2x.png";
import {validateNonBlankNumber, validateNonBlankString} from "../../utils/validation";
import LoadingButton from "@mui/lab/LoadingButton";
import IconPayCard from '../shared/IconPayCard';
import IconApplePay from '../shared/IconApplePay';
import IconGooglePay from '../shared/IconGooglePay';
import IconSbpPay from '../shared/IconSbpPay';
import Box from "@mui/material/Box";
import useDetectAndroid from "../../hooks/useDetectAndroid";

import RatingStar from "../shared/RatingStar";
import PaymentAmountPresets from "../shared/PaymentAmountPresets";
import {ThemeProvider} from "@mui/material";
import mainTheme from "../themes/mainTheme";
import gorillasPaymentTheme from "../themes/payments/gorillasPaymentTheme";
import gophrPaymentTheme from "../themes/payments/gophrPaymentTheme";

import {i18nPayments} from "../locale/i18n";
import {useTranslation} from "react-i18next";
import {getCurrency} from "./CurrencySelect";
import OVOIdInput from "./OVOIdInput";

const GophrLogo = React.lazy(() => import('./GophrLogo'));


interface ImpressionEmojiProps {
    imageUrl: string;
    imageUrl2x: string;
    selected: boolean;
}

export const ImpressionEmoji = styled.div<ImpressionEmojiProps>`
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1.0 : 0.3)};
  border: 0;
  outline: 0;
  background: url(${(props) => props.imageUrl}) no-repeat center center / 100%
  100%;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  max-width: 66px;
  max-height: 66px;

  &:hover {
    opacity: ${(props) => (props.selected ? 1.0 : 0.6)};
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2),
    /* returns 1.3,
    see Dev.Opera */
  only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
    background: url(${(props) => props.imageUrl2x}) no-repeat top left / 100%
    100%;
  }
`;

export type OptionalPresetAmount = number | undefined;

const PaymentFormPreviewAlert = () => {
    return (
        <Alert variant={"filled"} severity="info">
            This is how your payment form will look.
        </Alert>
    );
};

interface ImpressionPaths {
    url: string;
    url2x: string;
}

export const impressionsData: ImpressionPaths[] = [
    {
        url: Impression1ImagePath,
        url2x: Impression1ImagePath2x,
    },
    {
        url: Impression2ImagePath,
        url2x: Impression2ImagePath2x,
    },
    {
        url: Impression3ImagePath,
        url2x: Impression3ImagePath2x,
    },
    {
        url: Impression4ImagePath,
        url2x: Impression4ImagePath2x,
    },
    {
        url: Impression4ImagePath,
        url2x: Impression4ImagePath2x,
    },
];

const buildValidationMessage = (t: any, code: string | undefined) => {
    if (code && code.length > 0) {
        const presets = getCurrency(code);
        return `${t("payment.amountHint")} ${presets.minAmount}${formatCurrencyCodeSign(code)} to ${presets.maxAmount}${formatCurrencyCodeSign(code)}`
    } else {
        return ''
    }
}

interface PaymentFormMobilePaymentData {
    isAndroid: boolean;
    submitForm: (paymentMethod: PaymentMethod, currency: string | undefined) => void;
    isSubmitting: boolean | undefined;
    currencyCode: string | undefined;
}

const PaymentFormMobilePayment = ({
                                      isAndroid,
                                      submitForm,
                                      isSubmitting,
                                      currencyCode
                                  }: PaymentFormMobilePaymentData) => {

    return (
        <React.Fragment>
            {!isAndroid && (
                <Grid item mt={2}>
                    <LoadingButton
                        size="large"
                        fullWidth
                        variant="outlined"
                        startIcon={<IconApplePay/>}
                        onClick={() => {
                            submitForm("APPLE_PAY", currencyCode);
                        }}
                        loading={isSubmitting}
                    />
                </Grid>
            )}

            {isAndroid && (
                <Grid item mt={2}>
                    <LoadingButton
                        size="large"
                        fullWidth
                        variant="outlined"
                        startIcon={<IconGooglePay/>}
                        onClick={() => {
                            submitForm("GOOGLE_PAY", currencyCode);
                        }}
                        loading={isSubmitting}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};


export interface PaymentFormData {
    amount: string;
    totalAmount: string; // amount which includes fee
    includesFee: boolean;  // fee checkbox state
    commentOnly: boolean;  // comment only checkbox state
    comment?: string;
    paymentReceiver?: Establishment | User;
    rating?: number;
    ip: string;
    payer?: string;
}

export type PaymentMethod = "BANK_CARD" | "APPLE_PAY" | "GOOGLE_PAY" | "SBP_PAY" | "OVO_PAY" | "DOKU_CARD_PAY";

interface PaymentFormProps {
    paymentLink?: PaymentLink;
    previewMode?: boolean;
    onSubmit?: (data: PaymentFormData, paymentMethod: PaymentMethod) => void;
    isSubmitting?: boolean;
    paymentFormTheme?: string;
}

type PaymentFormState =
    | "NO_EMPLOYEES"
    | "EMPLOYEE_SELECTION"
    | "PAYMENT"
    | "UNKNOWN";

interface PaymentFormErrors {
    amount: string | null;
    comment: string | null;
    rating: string | null;
}

const PaymentForm = ({
                         paymentLink,
                         previewMode,
                         onSubmit,
                         isSubmitting,
                         paymentFormTheme,
                     }: PaymentFormProps) => {

    const {t} = useTranslation('payments', {i18n: i18nPayments});

    const theme = useTheme();

    const notMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const [isLoading, setIsLoading] = useState<boolean>(!paymentLink);

    const isAndroid = useDetectAndroid();

    const isTryCode = useMemo(() => {
        return paymentLink?.currencyCode === 'TRY';
    }, [paymentLink?.currencyCode])


    const [rating, setRating] = useState<number | undefined>(undefined);

    const [phoneNumber, setPhoneNumber] = useState<string>("");

    const [formErrors, setFormErrors] = useState<PaymentFormErrors>({
        amount: null,
        comment: null,
        rating: null,
    });

    const commentInput = useRef<HTMLInputElement>(null);

    const [needsEmployeeSelection, setNeedsEmployeeSelection] =
        useState<boolean>(false);

    const [paymentReceiver, setPaymentReceiver] = useState<Establishment | User | undefined>(undefined);

    const [paymentReceiverJob, setPaymentReceiverJob] = useState<string | undefined>(undefined);

    const [paymentAmount, setPaymentAmount] = useState<string>("");

    const [totalAmount, setTotalAmount] = useState<string>("0.00");

    const [includesFee, setIncludesFee] = useState<boolean>(true);

    const [commentOnly, setCommentOnly] = useState<boolean>(false);

    const [showOVOPayment, setShowOVOPayment] = useState<boolean>(false);

    let customTheme = mainTheme;

    let customPaymentTheme = false;
    if (paymentFormTheme === "gorillas") {
        customTheme = gorillasPaymentTheme;
        customPaymentTheme = true;
    } else if (paymentFormTheme === "gophr") {
        customTheme = gophrPaymentTheme;
        customPaymentTheme = true;
    }

    const longCurrencyValue = useMemo(() => {
        let longCurrencyValue = false;
        if (customPaymentTheme) {
            return true;
        }
        if (paymentLink) {
            return isLongCurrency(paymentLink.currencyCode);
        }
        return longCurrencyValue
    }, [paymentLink, customPaymentTheme]);

    useEffect(() => {
        const needsEmployeeSelection =
            !!paymentLink?.establishment && !!paymentLink?.allowsEmployeeSelection;
        setNeedsEmployeeSelection(needsEmployeeSelection);

        if (!needsEmployeeSelection) {
            if (paymentLink?.establishment) {
                setPaymentReceiver(paymentLink?.establishment);
            } else if (paymentLink?.user) {
                setPaymentReceiver(paymentLink?.user);
            }
        } else {
            setPaymentReceiver(undefined);
        }

        setIsLoading(false);
    }, [
        paymentLink?.establishment,
        paymentLink?.allowsEmployeeSelection,
        paymentLink?.user,
    ]);

    const submitForm = useCallback(
        (paymentMethod: PaymentMethod, currency: string | undefined) => {
            if (!onSubmit) {
                return;
            }

            let formErrors: PaymentFormErrors
            if (!commentOnly) {
                formErrors = {
                    amount: validateNonBlankString(paymentAmount, "Please enter an amount"),
                    comment: null,
                    rating: null,
                }
            } else {
                formErrors = {
                    amount: null,
                    comment: validateNonBlankString(commentInput.current!.value, "Please leave a comment"),
                    rating: validateNonBlankNumber(rating, "Please enter rating")
                }
            }

            setFormErrors(formErrors);
            const hasFormErrors = !!formErrors.amount || !!formErrors.comment || !!formErrors.rating;
            if (hasFormErrors) {
                return;
            }

            onSubmit(
                {
                    amount: clearCurrencyValue(paymentAmount, currency),
                    totalAmount: totalAmount, // amount with fee included
                    includesFee: includesFee,
                    commentOnly: commentOnly,
                    comment: commentInput.current!.value || undefined,
                    paymentReceiver: paymentReceiver,
                    rating: rating,
                    ip: "",
                    payer: phoneNumber,
                },
                paymentMethod
            );
        },
        [onSubmit, paymentAmount, totalAmount, includesFee, commentOnly, paymentReceiver, rating, phoneNumber]
    );

    const renderPaymentMethods = useCallback(() => {

        if (commentOnly) {
            return (
                <Grid item mt={2}>
                    <LoadingButton
                        size="large"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            submitForm("BANK_CARD", paymentLink?.currencyCode);
                        }}
                        loading={isSubmitting}
                    >
                        Leave a comment
                    </LoadingButton>
                </Grid>
            );
        } else {
            switch (paymentLink?.currencyCode) {
                case "IDR":
                    return (
                        <React.Fragment>
                            <Grid item mt={2}>
                                <LoadingButton
                                    size="large"
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        submitForm("DOKU_CARD_PAY", paymentLink?.currencyCode);
                                    }}
                                    loading={isSubmitting}
                                >
                                    Card payment
                                </LoadingButton>
                            </Grid>
                            {showOVOPayment ? (
                                <React.Fragment>
                                    <Grid item mt={2}>
                                        <OVOIdInput
                                            required
                                            margin={"none"}
                                            disabled={isSubmitting}
                                            InputProps={{
                                                sx: {
                                                    "& .MuiInputBase-input": {
                                                        textAlign: "center",
                                                        padding: 0,
                                                    },
                                                    "& ::-webkit-input-placeholder": {
                                                        textAlign: "center",
                                                    },
                                                    "& :-moz-placeholder": {
                                                        textAlign: "center",
                                                    },
                                                    "& ::-moz-placeholder": {
                                                        textAlign: "center",
                                                    },
                                                    "& :-ms-input-placeholder": {
                                                        textAlign: "center",
                                                    },
                                                    m: 1
                                                },
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="phone-number-input"
                                            label="OVO ID"
                                            value={phoneNumber}
                                            placeholder={"Phone number"}
                                            onFormat={(value) => {
                                                setPhoneNumber(value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item mt={2}>
                                        <LoadingButton
                                            size="large"
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {
                                                submitForm("OVO_PAY", paymentLink?.currencyCode);
                                            }}
                                            loading={isSubmitting}
                                        >
                                            Pay OVO Cash
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item mt={2}>
                                        <LoadingButton
                                            size="large"
                                            fullWidth
                                            variant="contained"
                                            startIcon={
                                                <ArrowBackIcon />
                                            }
                                            onClick={() => {
                                                setShowOVOPayment(!showOVOPayment);
                                            }}
                                            loading={isSubmitting}
                                        >
                                            To payment methods
                                        </LoadingButton>
                                    </Grid>
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <Grid item mt={2}>
                                        <LoadingButton
                                            size="large"
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {
                                                setShowOVOPayment(!showOVOPayment);
                                            }}
                                            loading={isSubmitting}
                                        >
                                            OVO Cash
                                        </LoadingButton>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    );
                default:
                    return (
                        <React.Fragment>

                            <PaymentFormMobilePayment
                                submitForm={submitForm}
                                isAndroid={isAndroid}
                                currencyCode={paymentLink?.currencyCode}
                                isSubmitting={isSubmitting}/>


                            {isTryCode && (
                                <Grid item mt={2}>
                                    <LoadingButton
                                        size="large"
                                        fullWidth
                                        variant="outlined"
                                        startIcon={<IconSbpPay/>}
                                        onClick={() => {
                                            submitForm("SBP_PAY", paymentLink?.currencyCode);
                                        }}
                                        loading={isSubmitting}
                                    />
                                </Grid>
                            )}

                            <Grid item mt={2}>
                                <LoadingButton
                                    size="large"
                                    fullWidth
                                    variant="outlined"
                                    startIcon={<IconPayCard/>}
                                    onClick={() => {
                                        submitForm("BANK_CARD", paymentLink?.currencyCode);
                                    }}
                                    loading={isSubmitting}
                                />
                            </Grid>

                        </React.Fragment>
                    )
            }
        }
    }, [
        paymentLink,
        commentOnly,
        isTryCode,
        isAndroid,
        isSubmitting,
        phoneNumber,
        submitForm,
        showOVOPayment
    ])

    const renderFormContent = useCallback(() => {
        let formState: PaymentFormState = "UNKNOWN";
        if (
            needsEmployeeSelection &&
            (!paymentLink?.employees || paymentLink?.employees?.length === 0)
        ) {
            formState = "NO_EMPLOYEES";
        } else if (needsEmployeeSelection && !paymentReceiver) {
            formState = "EMPLOYEE_SELECTION";
        } else if (paymentReceiver) {
            formState = "PAYMENT";
        }

        const calculateFee = (input: string, incFee: boolean, commOnly: boolean, currency: string | undefined) => {
            let clearPaymentAmount: string = input;
            let newTotalAmount: number;
            let newTotalAmountStr: string;
            let fee = 1.05;
            if (!incFee) {
                fee = 1.00;
            }
            if (commOnly) {
                newTotalAmount = 0
                newTotalAmountStr = newTotalAmount.toFixed(2)
                setTotalAmount(newTotalAmountStr)
            } else {
                newTotalAmount = parseFloat(clearCurrencyValue(clearPaymentAmount, currency)) * fee;
                if (isNaN(newTotalAmount)) {
                    newTotalAmount = 0
                }
                newTotalAmountStr = newTotalAmount.toFixed(2)
                if (currency && currency === 'IDR'){
                    newTotalAmountStr = Math.ceil(newTotalAmount).toFixed(2)
                }
                setTotalAmount(newTotalAmountStr)
            }
        }

        switch (formState) {
            case "NO_EMPLOYEES":
                return (
                    <Grid item mt={3}>
                        <Typography variant={"body1"} textAlign={"center"}>
                            Sorry, but this establishment has no employees that can receive
                            tips at the moment.
                        </Typography>
                        <Typography variant={"body1"} textAlign={"center"} mt={2}>
                            Please check again later or contact the establishment.
                        </Typography>
                    </Grid>
                );
            case "EMPLOYEE_SELECTION":
                return (
                    <React.Fragment>
                        <Grid item container spacing={0} mt={1} justifyContent={"center"}>
                            <EstablishmentLogo
                                size={"smallish"}
                                url={paymentLink!.establishment!.logo}
                            />
                        </Grid>
                        <Grid item mt={2} mb={1}>
                            <Typography variant={"body1"}>
                                {t("link.select")}
                            </Typography>
                        </Grid>
                        {paymentLink!.employees!.map((employment) => (
                            <Grid item mt={1} key={employment.id}>
                                <Card
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {background: "#F7F7FD"},
                                    }}
                                    onClick={() => {
                                        setPaymentReceiver(employment.employee);
                                        setPaymentReceiverJob(employment.job);
                                    }}
                                >
                                    <Grid container direction={"row"} alignItems={"center"} p={1}>
                                        <Grid item mr={3}>
                                            <UserAvatar
                                                size={"small"}
                                                url={employment.employee.avatar}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={"body1"}>
                                                {formatUserName(employment.employee)}
                                            </Typography>
                                            <Typography variant={"subtitle2"} mt={1}>
                                                {employment.job}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))}
                    </React.Fragment>
                );
            case "PAYMENT":
                const receiverIsUser = "firstName" in paymentReceiver!;

                return (

                    <React.Fragment>
                        {needsEmployeeSelection && (
                            <Grid item>
                                <Typography
                                    variant={"body2"}
                                    textAlign={"center"}
                                    sx={{
                                        cursor: "pointer",
                                        paddingLeft: "8px",
                                        paddingRight: "8px",
                                        paddingBottom: "4px",
                                        borderBottom: "1px solid #E8E6F8",
                                        "&:hover": {background: "#F7F7FD"},
                                    }}
                                    onClick={() => setPaymentReceiver(undefined)}
                                >
                                    ← {t("nav.back")}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item alignSelf={"center"}>
                            {paymentFormTheme === "gorillas" && (
                                receiverIsUser ? (
                                    <UserAvatar url={paymentReceiver!.avatar}/>
                                ) : (
                                    <EstablishmentLogo url={paymentReceiver!.logo}/>
                                )
                            )}

                            {paymentFormTheme === "gophr" && (
                                <GophrLogo/>
                            )}

                            {!customPaymentTheme && (
                                <>
                                    {receiverIsUser ? (
                                        <UserAvatar url={paymentReceiver!.avatar}/>
                                    ) : (
                                        <EstablishmentLogo url={paymentReceiver!.logo}/>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item alignSelf={"center"} mt={1}>
                            <Typography variant="h2" textAlign={"center"}>
                                {receiverIsUser
                                    ? formatUserNameWithDefault(paymentReceiver!, paymentLink?.qrCodeUserName)
                                    : paymentReceiver!.name}
                            </Typography>
                        </Grid>
                        {needsEmployeeSelection && (
                            <Grid item textAlign={"center"}>
                                <Typography
                                    textAlign={"center"}
                                    sx={{
                                        overflow: "hidden",
                                        fontSize: "14px",
                                    }}
                                    variant={"body1"}
                                >
                                    {paymentReceiverJob} at {paymentLink!.establishment?.name}
                                </Typography>
                            </Grid>
                        )}
                        {paymentLink!.paymentText && (
                            <Grid item>
                                <Typography
                                    textAlign={"center"}
                                    sx={{
                                        overflow: "hidden",
                                    }}
                                    variant={"body1"}
                                >
                                    {paymentLink!.paymentText}
                                </Typography>
                            </Grid>
                        )}

                        {paymentFormTheme !== "gophr" && (
                            <>
                                <Grid item mt={2}>
                                    <Typography
                                        textAlign={"center"}
                                        variant={"body1"}
                                    >
                                        {t("payment.amountHeader")}
                                    </Typography>
                                </Grid>
                                <Grid item mt={1}>
                                    <NumberFormat
                                        customInput={TextField}
                                        prefix={formatCurrencyCodeSign(paymentLink?.currencyCode)}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        type="text"
                                        fullWidth
                                        sx={{
                                            "& .MuiFormHelperText-root": {
                                                textAlign: "center",
                                            },
                                        }}
                                        InputProps={{
                                            sx: {
                                                fontSize: "24px",
                                                "& .MuiInputBase-input": {
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    textAlign: "center",
                                                },
                                            },
                                        }}
                                        id="amount"
                                        value={paymentAmount}
                                        onChange={(event) => {
                                            setPaymentAmount(event.currentTarget.value);
                                            calculateFee(event.currentTarget.value, includesFee, commentOnly, paymentLink?.currencyCode)
                                        }}
                                        {...customPaymentTheme ? null : {helperText: formErrors.amount || `${buildValidationMessage(t, paymentLink?.currencyCode)}`}}
                                        required
                                        autoComplete="off"
                                    />
                                </Grid>
                                <PaymentAmountPresets
                                    paymentLink={paymentLink!}
                                    onAmountSelected={(amount) => {
                                        const cCode = formatCurrencyCodeSign(paymentLink?.currencyCode)
                                        setPaymentAmount(cCode + amount.toString());
                                        calculateFee(cCode + amount.toString(), includesFee, commentOnly, paymentLink?.currencyCode)
                                    }}
                                />
                            </>
                        )}

                        {paymentFormTheme !== "gophr" && (
                            <Grid item>
                                <FormControlLabel
                                    sx={{width: "100%"}}
                                    value="start"
                                    control={customPaymentTheme ? (
                                        <Checkbox
                                            checked={includesFee}
                                            onChange={(event) => {
                                                setIncludesFee(event.target.checked)
                                                calculateFee(paymentAmount, event.target.checked, commentOnly, paymentLink?.currencyCode)
                                            }}
                                        />
                                    ) : (
                                        <Switch
                                            color="primary"
                                            checked={includesFee}
                                            onChange={(event) => {
                                                setIncludesFee(event.target.checked)
                                                calculateFee(paymentAmount, event.target.checked, commentOnly, paymentLink?.currencyCode)
                                            }}
                                        />
                                    )}
                                    label={t("link.fees")}
                                    labelPlacement={customPaymentTheme ? "end" : "start"}
                                />
                            </Grid>
                        )}

                        {customPaymentTheme && (
                            <>
                                <Grid item mt={2}>
                                    <Typography variant={"h3"} align={"center"}>
                                        Did you like everything?
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    flexWrap={"nowrap"}
                                    spacing={0}
                                    mt={1}
                                >
                                    {[1, 2, 3, 4, 5].map((ratingValue) => (
                                        <Grid item key={ratingValue}>
                                            <RatingStar
                                                selected={!!rating && rating >= ratingValue}
                                                onClick={() =>
                                                    setRating(
                                                        rating === ratingValue ? undefined : ratingValue
                                                    )
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                {formErrors.rating && (
                                    <Grid item xs={12}>
                                        <Typography color={"rgb(211, 47, 47)"} variant={"body2"} align={"center"}>
                                            {formErrors.rating}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item mt={1}>
                                    <TextField
                                        InputProps={{
                                            sx: {
                                                "& .MuiInputBase-input": {
                                                    textAlign: "center",
                                                },
                                                "& ::-webkit-input-placeholder": {
                                                    textAlign: "center",
                                                },
                                                "& :-moz-placeholder": {
                                                    textAlign: "center",
                                                },
                                                "& ::-moz-placeholder": {
                                                    textAlign: "center",
                                                },
                                                "& :-ms-input-placeholder": {
                                                    textAlign: "center",
                                                },
                                            },
                                        }}
                                        fullWidth
                                        id="comment"
                                        autoComplete="off"
                                        placeholder={"You can leave a comment"}
                                        multiline
                                        inputRef={commentInput}
                                    />
                                </Grid>
                                {formErrors.comment && (
                                    <Grid item xs={12}>
                                        <Typography color={"rgb(211, 47, 47)"} variant={"body2"} align={"center"}>
                                            {formErrors.comment}
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        )}

                        {paymentFormTheme === "gophr" && (
                            <>
                                <Grid item mt={2}>
                                    <Typography
                                        textAlign={"center"}
                                        variant={"h3"}
                                    >
                                        {t("link.thank")}
                                    </Typography>
                                </Grid>
                                <Grid item mt={1}>
                                    <NumberFormat
                                        customInput={TextField}
                                        prefix={formatCurrencyCodeSign(paymentLink?.currencyCode)}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        type="text"
                                        fullWidth
                                        sx={{
                                            "& .MuiFormHelperText-root": {
                                                textAlign: "center",
                                            },
                                        }}
                                        InputProps={{
                                            sx: {
                                                fontSize: "24px",
                                                "& .MuiInputBase-input": {
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    textAlign: "center",
                                                },
                                            },
                                        }}
                                        id="amount"
                                        value={paymentAmount}
                                        onChange={(event) => {
                                            setPaymentAmount(event.currentTarget.value);
                                            calculateFee(event.currentTarget.value, includesFee, commentOnly, paymentLink?.currencyCode)
                                        }}
                                        required
                                        autoComplete="off"
                                    />
                                </Grid>
                                <PaymentAmountPresets
                                    paymentLink={paymentLink!}
                                    onAmountSelected={(amount) => {
                                        const cCode = formatCurrencyCodeSign(paymentLink?.currencyCode)
                                        setPaymentAmount(cCode + amount.toString());
                                        calculateFee(cCode + amount.toString(), includesFee, commentOnly, paymentLink?.currencyCode)
                                    }}
                                />
                            </>
                        )}

                        {paymentFormTheme === "gophr" && (
                            <Grid item>
                                <Box
                                    {...(paymentFormTheme === "gophr") ? {
                                        sx: {
                                            borderBottom: 1,
                                            borderColor: customTheme.palette.primary.main
                                        }
                                    } : {}}
                                >
                                    <FormControlLabel
                                        sx={{width: "100%"}}
                                        value="start"
                                        control={customPaymentTheme ? (
                                            <Checkbox
                                                checked={includesFee}
                                                onChange={(event) => {
                                                    setIncludesFee(event.target.checked)
                                                    calculateFee(paymentAmount, event.target.checked, commentOnly, paymentLink?.currencyCode)
                                                }}
                                            />
                                        ) : (
                                            <Switch
                                                color="primary"
                                                checked={includesFee}
                                                onChange={(event) => {
                                                    setIncludesFee(event.target.checked)
                                                    calculateFee(paymentAmount, event.target.checked, commentOnly, paymentLink?.currencyCode)
                                                }}
                                            />
                                        )}
                                        label="Include a 5% fee in payment"
                                        labelPlacement={customPaymentTheme ? "end" : "start"}
                                    />
                                </Box>
                            </Grid>
                        )}

                        {!customPaymentTheme && (
                            <>
                                <Grid item>
                                    <FormControlLabel
                                        sx={{width: "100%"}}
                                        value="start"
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={commentOnly}
                                                onChange={(event) => {
                                                    setCommentOnly(event.target.checked)
                                                    calculateFee(paymentAmount, includesFee, event.target.checked, paymentLink?.currencyCode)
                                                }}
                                            />
                                        }
                                        label={t("link.comment_only")}
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item mt={2}>
                                    <Typography variant={"body1"} align={"center"}>
                                        {t("link.impression")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    flexWrap={"nowrap"}
                                    spacing={0}
                                >
                                    {[1, 2, 3, 4, 5].map((ratingValue) => (
                                        <Grid item key={ratingValue} flexBasis={"20%"}>
                                            <ImpressionEmoji
                                                imageUrl={impressionsData[ratingValue - 1].url}
                                                imageUrl2x={impressionsData[ratingValue - 1].url2x}
                                                selected={!!rating && rating >= ratingValue}
                                                onClick={() =>
                                                    setRating(
                                                        rating === ratingValue ? undefined : ratingValue
                                                    )
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}

                        {!customPaymentTheme && (
                            <>
                                <Grid item mt={2}>
                                    <Typography variant={"body1"} align={"center"}>
                                        {t("link.like")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    flexWrap={"nowrap"}
                                    spacing={0}
                                    mt={1}
                                >
                                    {[1, 2, 3, 4, 5].map((ratingValue) => (
                                        <Grid item key={ratingValue}>
                                            <RatingStar
                                                selected={!!rating && rating >= ratingValue}
                                                onClick={() =>
                                                    setRating(
                                                        rating === ratingValue ? undefined : ratingValue
                                                    )
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                {formErrors.rating && (
                                    <Grid item xs={12}>
                                        <Typography color={"rgb(211, 47, 47)"} variant={"body2"} align={"center"}>
                                            {formErrors.rating}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item mt={1}>
                                    <TextField
                                        InputProps={{
                                            sx: {
                                                "& .MuiInputBase-input": {
                                                    textAlign: "center",
                                                },
                                                "& ::-webkit-input-placeholder": {
                                                    textAlign: "center",
                                                },
                                                "& :-moz-placeholder": {
                                                    textAlign: "center",
                                                },
                                                "& ::-moz-placeholder": {
                                                    textAlign: "center",
                                                },
                                                "& :-ms-input-placeholder": {
                                                    textAlign: "center",
                                                },
                                            },
                                        }}
                                        fullWidth
                                        id="comment"
                                        autoComplete="off"
                                        placeholder={`${t("payment.commentHint")}`}
                                        multiline
                                        inputRef={commentInput}
                                    />
                                </Grid>
                                {formErrors.comment && (
                                    <Grid item xs={12}>
                                        <Typography color={"rgb(211, 47, 47)"} variant={"body2"} align={"center"}>
                                            {formErrors.comment}
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        )}

                        <Grid
                            item
                            {...customPaymentTheme ? {container: true} : {item: true}}
                            {...customPaymentTheme ? {
                                justifyContent: "space-between",
                                alignItems: "center"
                            } : null}
                        >
                            {customPaymentTheme && (
                                <Typography variant={"body1"} sx={{color: "#FFFFFF"}}>Total amount</Typography>
                            )}

                            <Typography variant={"body2"} sx={{textAlign: "right"}}>
                                <Box
                                    component={"span"}
                                    sx={{
                                        color: customPaymentTheme ? "#FFFFFF" : "#333333",
                                        fontSize: longCurrencyValue ? 24 : 56,
                                        fontWeight: "bold",
                                        mr: 2,
                                    }}
                                >
                                    {totalAmount} {formatCurrencyCodeSign(paymentLink?.currencyCode)}
                                </Box>
                            </Typography>
                        </Grid>

                        {renderPaymentMethods()}

                        <Grid item mt={2} style={{textAlign: "center"}}>
                            <Link
                                color={"#636363"}
                                target="_blank"
                                rel="noreferrer"
                                href="https://hosted-user-data.s3.eu-central-1.amazonaws.com/GFI+iTips+T%26C.pdf"
                            >
                                GFI iTips T&C
                            </Link>
                        </Grid>
                    </React.Fragment>
                );
        }
    }, [
        formErrors.amount,
        formErrors.comment,
        formErrors.rating,
        needsEmployeeSelection,
        paymentAmount,
        totalAmount,
        includesFee,
        commentOnly,
        paymentLink,
        paymentReceiver,
        paymentReceiverJob,
        rating,
        paymentFormTheme,
        customTheme.palette.primary.main,
        customPaymentTheme,
        longCurrencyValue,
        t,
        renderPaymentMethods,
    ]);

    return (
        <div style={{maxWidth: "430px", width: "100%"}}>
            {isLoading ? (
                <InitialLoading/>
            ) : (
                <ThemeProvider theme={customTheme}>
                    <Paper
                        elevation={notMobile ? 1 : 0}
                        sx={{
                            pt: notMobile ? 4 : 0,
                            pb: 4,
                            pl: notMobile ? 8 : 4,
                            pr: notMobile ? 8 : 4,
                            width: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <Grid container direction="column" spacing={1} flexShrink={1}>
                            <Grid item textAlign={"center"} mb={2} zIndex={1}>
                                <AppLogo size={"small"}/>
                                {previewMode && (
                                    <PaymentFormPreviewAlert/>
                                )}
                            </Grid>
                            {renderFormContent()}
                        </Grid>
                    </Paper>
                </ThemeProvider>
            )}
        </div>
    );
};
export default PaymentForm;
